import React from "react"
import Helmet from "react-helmet"
import { decodeHTML } from "../components/helpers/helpers"
import config from "../../site-config"



const Seo = ({ data }) => {
  if (!data || !data.seo) return null

  const {
    metaTitle,
    metaDesc,
    canonicalUrl,
    metaRobotsNoindex,
    metaRobotsNofollow,
    metaRobotsArchive,
    metaRobotsImageIndex,
    opengraphTitle,
    opengraphDescription,
    opengraphImage,
    twitterTitle,
    twitterDescription,
    twitterImage,
  } = data.seo

  
  const slug = data.uri
  
  const postURL = `/`
    
  const pageTitle = `${(opengraphTitle ? decodeHTML(opengraphTitle) : decodeHTML(metaTitle)) ? opengraphTitle ? decodeHTML(opengraphTitle) : decodeHTML(metaTitle) : config.siteTitle} | Loaded Reports`

  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: process.env.GATSBY_SITEURL,
      name: pageTitle,
      alternateName: "Loaded Reports",
    },
    {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": postURL,
            name: pageTitle,
            image: opengraphImage ? opengraphImage : `${process.env.GATSBY_SITEURL}/fb.png`,
          },
        },
      ],
    },
    {
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      url: process.env.GATSBY_SITEURL,
      name: pageTitle,
      alternateName: "Loaded Reports",
      headline: pageTitle,
      image: {
        "@type": "ImageObject",
        url: opengraphImage ? opengraphImage : `${process.env.GATSBY_SITEURL}/fb.png`,
      },
      description: metaDesc,
    },
  ]

  let robotsString = []
  if (metaRobotsArchive) robotsString.push(`noarchive`);
  if (metaRobotsNoindex) robotsString.push(`noindex`);
  if (metaRobotsNofollow) robotsString.push(`nofollow`);
  if (metaRobotsImageIndex) robotsString.push(`noimageindex`);

  return (
    <Helmet>
      {/* General tags */}
      <title>
        {pageTitle}
      </title>
      <meta name="description" content={metaDesc} />
      <meta name="image" content={opengraphImage ? opengraphImage : `${process.env.GATSBY_SITEURL}/fb.png`} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
     
      <meta name="robots" content="all" />
    
      {/* OpenGraph tags */}
      <meta
        property="og:url"
        content={`${process.env.GATSBY_SITEURL}/${slug}`}
      />
      {/* {isBlog ? <meta property="og:type" content="article" /> : null} */}
      <meta
        property="og:title"
        content={
          pageTitle
        }
      />
      <meta property="og:description" content={opengraphDescription ? opengraphDescription : metaDesc ? metaDesc : ""} />
      <meta
        property="og:image"
        content={opengraphImage ? opengraphImage : `${process.env.GATSBY_SITEURL}/fb.png`}
      />
      
      <link rel="canonical" href={canonicalUrl} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content={`${process.env.GATSBY_SITEURL}/${slug}`}
      />
      <meta name="twitter:creator" content={"Loaded Reports"} />
      <meta
        name="twitter:title"
        content={
          twitterTitle
            ? twitterTitle
            : pageTitle
        }
      />
      <meta
        name="twitter:description"
        content={
          twitterDescription ? twitterDescription : metaDesc ? metaDesc : ""
        }
      />
      <meta
        name="twitter:image"
        content={
          twitterImage ? twitterImage : opengraphImage ? opengraphImage : `${process.env.GATSBY_SITEURL}/fb.png`
        }
      />
    </Helmet>
  )
}

export default Seo

import { createGlobalStyle } from "styled-components"
import "normalize.css/normalize.css"

import {device, size} from "./MediaQueries"

const GlobalStyle = createGlobalStyle`

	html {
		box-sizing: border-box;
		font-family: ${props => props.theme.fontPrimary};
		font-size: 62.5%;
		/* BETTER FONT SMOOTHING - https://gist.github.com/hsleonis/55712b0eafc9b25f1944 */
		font-variant-ligatures: none;
		-webkit-font-variant-ligatures: none;
		text-rendering: optimizeLegibility;
		-moz-osx-font-smoothing: grayscale;
		font-smoothing: antialiased;
		-webkit-font-smoothing: antialiased;
		text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;

		@media ${device.MXsm} {
			&.scroll--fixed {
				overflow: hidden;
			}
		}
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	::-webkit-scrollbar
	{
		width: 10px;
		background-color: ${props => props.theme.offWhite};
	}

	::-webkit-scrollbar-thumb
	{
		background-color: ${props => props.theme.black};
		border: 2px solid ${props => props.theme.black};;
	}

	a {
		color: ${props => props.theme.primary};
		text-decoration: none;

		${'' /* &:active,
		&:focus,
		&:hover {
			text-decoration: underline;
		} */}
	}

	body {
		overflow-x: hidden;
    font-family: ${props => props.theme.fontPrimary};
		scroll-behavior: smooth;
		background: ${props => props.theme.white};
		color: ${props => props.theme.black};
		font-size:  ${props => props.theme.font_0};
		line-height: 1.5;
	}

	section {
	
		.inner-div {
			padding: ${props => props.theme.responsivePadding};
			max-width: ${size.lg};
			margin: 0 auto;
		}
		
	}

  .site-container {
    align-items: flex-start;
    box-sizing: inherit;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden auto;
    overflow-x: hidden;
    width: 100vw;

		& > div {
			display: block;
			width: 100%;
		}
  }

	.wrapper {
		@supports (display: flex) {
			display: flex;
			flex-direction: column;
		}
	}

	/* Common base styles for the site */
	img, svg, video {
    height: auto;
    max-width: 100%;
    vertical-align: top; 
    border-style: none; 
	}

	video {
		display: block;
		width: 100%;
	}

	button,
	input[type="submit"] {
		display: flex;
		align-items: center;
		padding: 16px 24px;

		background-color: ${props => props.theme.primary};
		border: none;
		box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
		color: ${props => props.theme.white};
		cursor: pointer;
		font-weight: 400;
		line-height: 1;
		outline: none;
		text-decoration: none;
		transition: all 0.15s ease;
		white-space: nowrap;

		&:hover {
			transform: translateY(-1px);
		}

		&:focus,
		&:hover {
			box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
		}
	}


	h1, h2, h3, h4, h5, h6 {
		line-height: 1.25;
		margin: 0 0 8px 0;
    font-family: ${props => props.theme.fontPrimary};
		font-weight: 700;
		text-transform: normal;
	}

	h1,
	.h1 {
		font-family: ${props => props.theme.fontSecondary};
    font-size: ${props => props.theme.font_6};
		font-weight: 400;
		line-height: 1.1;
		letter-spacing: -1px;
	}

	h2,
	.h2 {
    font-size: ${props => props.theme.font_5};
		font-weight: 500;
		margin-bottom: 8px;
	}

	h3,
	.h3 {
    font-size: ${props => props.theme.font_4};
	}

	h4,
	.h4 {
    font-size: ${props => props.theme.font_3};
	}

	h5,
	.h5 {
    font-size: ${props => props.theme.font_2};
	}

	h6,
	.h6 {
    font-size: ${props => props.theme.font_1};
	}

	h7,
	.h7 {
    font-size: ${props => props.theme.font_0};
	}

	code,
	pre {
		background: none;
		color: ${props => props.theme.white};
		font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
		font-size: 1em;
		hyphens: none;
		line-height: 1.5;
		tab-size: 4;
		text-align: left;
		text-shadow: 0 -0.1em 0.2em ${props => props.theme.black};
		white-space: pre;
		word-break: normal;
		word-spacing: normal;
		word-wrap: normal;

		::-webkit-scrollbar
		{
			background-color: ${props => props.theme.greyLight};
		}

		::-webkit-scrollbar-thumb
		{
			background-color: ${props => props.theme.greyDark};
			border: 2px solid ${props => props.theme.greyDark};
		}

		a {
			color: inherit;
		}
	}

	@media print {
		code,
		pre {
			text-shadow: none;
		}
	}

	pre,
	:not(pre) > code {
		background: ${props => props.theme.black};
	}

	/* Code blocks */
	pre {
		padding: 1em;
		margin: 32px 0;
		overflow: auto;
		border: 0.3em solid ${props => props.theme.black};
		border-radius: 0.5em;
		box-shadow: 1px 1px 0.5em black inset;
	}

	/* Inline code */
	:not(pre) > code {
		padding: 0.15em 0.2em 0.05em;
		border-radius: 0.3em;
		border: 0.13em solid hsl(0, 0%, 11%);
		box-shadow: none;
		white-space: normal;
	}

	/* Slick Slider */
	.slick-dots li button {
		box-shadow: none !important;
	}

	.slick-arrow {
		display: none !important;
	}

	.mailtoui-button-copy {
		align-items: center;

		> * {
			top: auto;
		}
	}

	.wp-block-image {
		text-align: center;

		img {
			margin: 0 auto;
		}
	}

	.wp-block-embed-youtube {
		.wp-block-embed__wrapper {
			height: 0;
			padding-top: 56.25%;
			position: relative;

			iframe {
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
	}
`

export default GlobalStyle
